import React from 'react';

import CreativeCommonsInfo from '../components/CreativeCommonsInfo';
import dictionary from '../data/_dictionary';

const IssueContent = ({content, lang}) => {
    const {
        frontmatter: {
            abstracts,
            editors,
            issue,
            pdf,
            book,
            cover,
            title,
            year,
        },
    } = content;

    return (
        <div className="select-enabled">
            <CreativeCommonsInfo lang={lang} dividerBottom />
            <div className="cover-container">
                <img  className="cover" src={cover} alt="cover" />
            </div>
            <h2>Orbis Linguarum</h2>
            <h3>{`${dictionary.issue[lang]} ${issue} (${year})${title ? `: ${title}` : ''}`}</h3>
            <p>{editors[lang]}</p>

            <div>→ <a href={pdf}>{dictionary.contents[lang]}</a></div>
            {abstracts && <div>→ <a href={abstracts}>{dictionary.abstracts[lang]}</a></div>}
            {book && <div>→ <a href={book}>PDF</a></div>}
        </div>
    );
};

export default IssueContent;
